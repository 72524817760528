button.scroltop {
    bottom: 15px;
    border: none;
    display: none;
    position: fixed;
    background: transparent;
    right: 15px;
    width: 80px;
    height: 80px;
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
}


@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400);


.scrollbutton a {
    position: absolute;
    bottom: 11px;
    left: 0%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #1e7256;
    font: normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
}

#section10 a {
    padding-top: 60px;
}

#section10 a span {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
    transform: unset;
}

#section10 a span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
}

#section10 a span:hover {
    transform: scale(1.15);
}

@-webkit-keyframes sdb10 {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes sdb10 {
    0% {
        opacity: 0;
    }

    40% {
        transform: translate(0, 20px);
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        transform: translate(0, 0);

        opacity: 0;
    }
}