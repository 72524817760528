.db_face_images {
  width: 320px;
  height: 320px;
  border-radius: 100%;
  -box-shadow: -1rem 0 3rem rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  margin-top: -150px;
  position: relative;
  z-index: 999;
}

.db_face_images img {
  border: 5px solid #1e7256;
  border-radius: 100%;
}

.db_face_images {
  width: 220px;
  margin: -70px auto 30px !important;
  height: 220px;
}

.circle-ripple {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
}

.circle-ripple {
  background-color: transparent;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(28, 182, 100, 0.2),
      0 0 0 0.1em rgba(28, 182, 120, 0.2), 0 0 0 0.5em rgba(28, 182, 95, 0.2),
      0 0 0 1em rgba(28, 182, 156, 0.2);
  }

  100% {
    box-shadow: 0 0 0 0.11em rgba(28, 182, 90, 0.2),
      0 0 0 0.5em rgba(28, 182, 79, 0.2), 0 0 0 1em rgba(28, 182, 95, 0.2),
      0 0 0 2em rgba(28, 182, 100, 0);
  }
}

.sidebar {
  position: fixed;
  width: 306px;
  transition: all 0.3s ease-in-out;
}

.is-fixed {
  position: fixed;
  top: 0px;
  width: 306px;
  margin-top: 1.875rem;
  transition: all 0.3s ease-in-out;
}

.is-fixed .db_face_images {
  margin: 0px auto 30px !important;
  transition: all 0.3s ease-in-out;
}

.badge {
  background-color: #1e7256;
}

.social {
  padding: inherit;
  margin: 10px auto;
}

.social li {
  display: inline-block;
  margin: 0 5px;
}

.social li a {
  font-size: 20px;
  color: #1e7256;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  display: block;
  border-radius: 100%;
  border: 1px solid #1e7256;
  transition: all 0.3s cubic-bezier(0.34, 0.14, 1, 1);
}

.social li a:hover {
  background: #1e7256;
  border-color: #ffffff;
  color: #ffffff;
}

.icon-sideBar {
  font-size: 1.2rem;
  margin: 0 9px;
}

.aws-btn {
  --button-default-height: 58px;
  --button-default-font-size: 16px;
  --button-default-border-radius: 8px;
  --button-horizontal-padding: 25px;
  --button-raise-level: 6px;
  --button-hover-pressure: 2.75;
  --transform-speed: 0.375s;
}

.aws-btn--primary .aws-btn__content {
  background-color: #1e7256 !important;
}

.aws-btn--primary .aws-btn__wrapper:before {
  background: #144032;
}

.box-inner {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* .aws-btn--primary .aws-btn__content:hover {
    background-color: #386657 !important;

} */

@media (max-width: 1400px) {
  .sidebar {
    left: 3rem;
  }
}

@media (max-width: 1200px) {
  .sidebar {
    position: unset;
    width: unset;
  }
}
