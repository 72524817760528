.form-group input {
  background-color: #f5f8f9;
  border: 1px solid #f5f8f9;
  border-radius: 20px;
  box-shadow: none;
  color: #44566c;
  font-size: inherit;
  height: auto;
  padding: 0.875rem 1.375rem 0.8125rem;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 3.375rem;
}

.form-group .textarea {
  border-radius: 20px;
  display: block;
  padding: 0.8125rem 1.1875rem;
  resize: none;
  background-color: #f5f8f9;
  border: 1px solid #f5f8f9;
  box-shadow: none;
  color: #44566c;
  font-size: inherit;
  height: auto;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:focus {
  color: #212529;
  /* background-color: #fff; */
  border-color: #1e7256;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(30 114 86 / 14%);
}

.contact_btn {
  background-color: #1e7256f5;
  border-radius: 20px;
  border: 0;
  box-shadow: 0 0.5rem 1rem rgb(48 76 253 / 15%),
    0 0.125rem 0.25rem rgb(48 76 253 / 15%);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  padding: 0.9rem 2rem;
  position: relative;
  transition: 0.4s ease-in-out;
  white-space: nowrap;
  width: auto;
}
.contact_btn:hover {
  background-color: #fff;
  color: #1e7256f5;
}

.text-right {
  text-align: right;
}

.font-icon {
  margin-right: 8px;
}

.contact-form {
  padding-bottom: 1.875rem;
  /* background-image: url('../../../assets/images/contact.png'); */
}
