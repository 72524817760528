.inner-menu {
    width: 80%;
    font-size: 1.25em;
}

.inner-menu ul {
    width: inherit;

}

.nav__item a.active,
.nav__item a:hover {
    color: #1e7256;
    background: white;
    border-radius: 37px;
    padding: 9px 16px;
    margin: 0 -16px;
}






@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}



.hamburger-button {
    outline: none;
    border: none;
    cursor: pointer;
    width: 1.5em;
    height: 2em;
    position: relative;
    z-index: 10;
    background-color: transparent;
    background-image: url(https://res.cloudinary.com/dnpyq0ocz/image/upload/v1588441892/icon-hamburger.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-tap-highlight-color: transparent;
}

.hamburger-button.open {
    background-image: url(https://res.cloudinary.com/dnpyq0ocz/image/upload/v1588441892/icon-hamburger-close.svg);
}



.inner-menu.open {
    visibility: visible;
    right: 0;
    opacity: 1;
    transition: all 0.6s cubic-bezier(0.42, 0, 0.34, 1.01);
    width: 80%;

}

.inner-menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 0;

    transition: all 0.6s cubic-bezier(0.42, 0, 0.34, 1.01);
}


@media(max-width:768px) {
    .inner-menu.open {
        width: 90%;
    }
}

@media only screen and (max-width: 580px) {
    .inner-menu.open {
        width: 100%;
    }

    .nav {
        justify-content: space-evenly;
    }

    .nav>* {
        min-width: 0;
    }

    .nav__item a {
        color: white;
    }
}