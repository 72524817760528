/* ------------------------------------------------
 Author: ArtStyles (Art)
 
 Template Name: vCard
 version: 1.1.0
------------------------------------------------ */
/* ------------------------------------------------

 - General Styles
 - Background
 - Typography
 - Preloader
 - Form
 - Button
 - Preloader
 - Sidebar
 - Sections
 - Navbar
 - Footer
 - Cases
 - Clients
 - Contact
 - Single post
 - Footer
 - Contact
 - Portfolio
 - News
 - Single news
 - Testimonials
 - Timeline
 - Other
 
------------------------------------------------ */
/* @import url(vendors/bootstrap.min.css);
@import url(vendors/swiper.min.css);
@import url(vendors/simplebar.min.css); */
/* @import url(../icons/style.css); */
@import url("https://fonts.googleapis.com/css?family=Archivo:400,400i,500,500i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,900&display=swap");

/* -----------------------------------------------------------------
    - General Styles
----------------------------------------------------------------- */
*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-x: hidden;
}

body,
html {
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: #f5f6f7;
  color: #44566c;
  font-family: "Archivo", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.65;
  min-height: 100vh;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 580px) {
  body {
    line-height: 1.6;
  }
}

html.is-scroll-disabled body {
  height: 100vh;
  overflow: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.is-scroll-disabled {
    overflow: hidden;
  }
}

.no-scroll {
  overflow: hidden;
  -ms-overflow-style: none;
}

.no-scroll-height {
  overflow-x: hidden;
}

.svg-defs {
  position: absolute;
  height: 0;
  width: 0;
}

.bg-triangles {
  /* background-image: url(../images/triangles-top.svg), url(../images/triangles-bottom.svg); */
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: inherit;
}

@media only screen and (max-width: 580px) {
  .bg-triangles {
    background-size: contain;
  }
}

.bg-triangles-2 {
  /* background-image: url(../images/triangle-2-top.svg), url(../images/triangle-2-bottom.svg); */
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  background-size: 50%;
  background-attachment: fixed;
}

@media only screen and (max-width: 991px) {
  .bg-triangles-2 {
    background-size: contain;
  }
}

.bg-peach {
  background-image: linear-gradient(115.29deg, #fff2e7 1.33%, #ffcada 98.21%);
  background-attachment: fixed;
}

.bg-green {
  background-image: linear-gradient(115.29deg, #0dc8a9 1.33%, #32cbc8 98.21%);
  background-attachment: fixed;
}

.bg-green-2 {
  background-image: linear-gradient(135deg, #9be15d 0%, #00d9a6 100%);
  background-attachment: fixed;
}

/* -----------------------------------------------------------------
    - Typography
----------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  line-height: 1.3;
}

.title--h1 {
  font-size: 2.5rem;
  margin-bottom: 1.0625rem;
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #44566c;
}

@media only screen and (max-width: 580px) {
  .title--h1 {
    font-size: 1.75rem;
  }
}

.title--h2 {
  font-size: 1.75rem;
}

@media only screen and (max-width: 580px) {
  .title--h2 {
    font-size: 1.5rem;
  }
}

.title--h3 {
  font-size: 1.8rem;
  margin-bottom: 1.3125rem;
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #44566c;
}

@media only screen and (max-width: 580px) {
  .title--h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}

.title--h4 {
  font-size: 1.25rem;
  margin-bottom: 0.8125rem;
}

@media only screen and (max-width: 580px) {
  .title--h4 {
    font-size: 1rem;
  }
}

.title--h5 {
  font-size: 1rem;
}

@media only screen and (max-width: 580px) {
  .title--h5 {
    font-size: 0.9375rem;
  }
}

.title--h6 {
  font-size: 0.75rem;
}

.title--center {
  text-align: center;
}

.title--tone {
  color: #1e7256;
}

.title-icon {
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  height: 2.5rem;
  width: 2.5rem;
}

@media only screen and (max-width: 580px) {
  .title-icon {
    height: 2.0833333333rem;
    width: 2.0833333333rem;
  }
}

.title__separate {
  margin-bottom: 2.3125rem;
  position: relative;
}

.title__separate::before {
  content: "";
  background-color: #1e7256;
  border-radius: 0.5rem;
  height: 0.3125rem;
  position: absolute;
  bottom: -1.0625rem;
  left: 0;
  width: 3.125rem;
  z-index: 1;
}

@media only screen and (max-width: 580px) {
  .title__separate {
    margin-bottom: 1.5rem;
  }

  .title__separate::before {
    height: 0.1875rem;
    bottom: -0.5625rem;
    width: 3rem;
  }
}

@media only screen and (max-width: 991px) {
  .first-title {
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 991px) {
  .open-menu .first-title {
    padding-top: 2.5625rem;
  }
}

@media only screen and (max-width: 580px) {
  .open-menu .first-title {
    padding-top: 0;
  }
}

.weight--400 {
  font-weight: 400;
}

.weight--500 {
  font-weight: 500;
}

.weight--600 {
  font-weight: 600;
}

.weight--700 {
  font-weight: 700;
}

.color--light {
  color: #8697a8;
}

.color--dark {
  color: #44566c;
}

.block-quote {
  font-size: 1.3125rem;
  font-weight: 400;
  font-style: italic;
  margin: 1.8125rem 0;
  position: relative;
  line-height: 1.6;
}

@media only screen and (max-width: 991px) {
  .block-quote {
    padding-left: 3.8125rem;
  }
}

@media only screen and (max-width: 580px) {
  .block-quote {
    font-size: 1.125rem;
    margin: 1rem 0;
    padding-left: 0;
    padding-top: 2.8125rem;
  }

  .block-quote p {
    margin-bottom: 0.5rem;
  }
}

.block-quote::before {
  /* background-image: url(../images/icon-quote.svg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  height: 2.125rem;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: -3.375rem;
  width: 2.125rem;
}

@media only screen and (max-width: 991px) {
  .block-quote::before {
    left: 0;
  }
}

.block-quote__author {
  font-size: 1rem;
  font-style: normal;
  display: block;
  margin-top: 0.5625rem;
  padding-left: 3rem;
  position: relative;
}

@media only screen and (max-width: 580px) {
  .block-quote__author {
    font-size: 0.875rem;
    margin-top: 0;
    padding-left: 2rem;
  }
}

.block-quote__author::before {
  content: "";
  background-color: #44566c;
  height: 0.0625rem;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2rem;
}

@media only screen and (max-width: 580px) {
  .block-quote__author::before {
    width: 1rem;
  }
}

a {
  color: #1e7256;
  cursor: pointer;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  transition: color 0.3s cubic-bezier(0.72, 0.16, 0.345, 0.875);
}

a:hover {
  color: #1e7256;
  outline: none;
  text-decoration: underline;
}

a.decoration--none {
  text-decoration: none;
}

.noGutters-Top {
  margin-top: 0 !important;
}

.noGutters-Bottom {
  margin-bottom: 0 !important;
}

.noGutters-Left {
  margin-left: 0 !important;
}

.noGutters-Right {
  margin-right: 0 !important;
}

.description {
  color: #f5f8f9;
  margin-bottom: 1.875rem;
}

.foreword {
  color: #f5f8f9;
  font-size: 1.125rem;
}

img {
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

iframe {
  background-color: #44566c;
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
}

.cover {
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
  height: 100%;
  width: 100%;
}

::-moz-selection {
  background: transparent;
  color: #1e7256;
}

::selection {
  background: transparent;
  color: #1e7256;
}

/* -----------------------------------------------------------------
    - Form
----------------------------------------------------------------- */
.input,
.textarea,
select,
.custom-select {
  background-color: #f5f8f9;
  border: 1px solid #f5f8f9;
  border-radius: 20px;
  box-shadow: none;
  color: #44566c;
  font-size: inherit;
  height: auto;
  padding: 0.875rem 1.375rem 0.8125rem;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 580px) {
  .input,
  .textarea,
  select,
  .custom-select {
    padding: 0.8125rem 1.375rem 0.75rem;
  }
}

.input:focus,
.textarea:focus,
select:focus,
.custom-select:focus {
  background-color: #f5f8f9;
  color: #44566c;
  box-shadow: none;
  outline: none;
}

.input {
  line-height: inherit;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .input {
    line-height: 0;
  }
}

.input--white {
  background-color: #fff;
}

.input--white:focus {
  background-color: #fff;
}

.input__icon {
  padding-left: 3.375rem;
}

@media only screen and (max-width: 580px) {
  .input__icon {
    padding-left: 2.8125rem;
  }
}

.textarea {
  border-radius: 20px;
  display: block;
  padding: 0.8125rem 1.1875rem;
  resize: none;
}

.textarea--white {
  background-color: #fff;
}

.textarea--white:focus {
  background-color: #fff;
}

.form-group {
  margin-bottom: 2rem;
  position: relative;
}

@media only screen and (max-width: 580px) {
  .form-group {
    margin-bottom: 1.6875rem;
  }
}

.form-group .font-icon {
  color: #8697a8;
  font-size: 1.0625rem;
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2.375rem;
  height: 1rem;
  width: 1rem;
  z-index: 1;
}

@media only screen and (max-width: 580px) {
  .form-group .font-icon {
    font-size: 1rem;
    left: 2.0625rem;
  }
}

.help-block {
  position: absolute;
  font-size: 0.75rem;
  line-height: 1.4;
  padding-top: 0.3125rem;
  padding-left: 0.375rem;
}

@media only screen and (max-width: 580px) {
  .help-block {
    font-size: 0.6875rem;
  }
}

.with-errors {
  color: #ff3d57;
}

.has-error input,
.has-error textarea,
.has-error input:focus,
.has-error textarea:focus {
  border-color: #ff3d57;
}

#validator-contact {
  font-size: 0.875rem;
  margin-top: 1rem;
}

.form-control::-webkit-input-placeholder {
  color: #8697a8;
}

.form-control::-moz-placeholder {
  color: #8697a8;
}

.form-control:-ms-input-placeholder {
  color: #8697a8;
}

.form-control::-ms-input-placeholder {
  color: #8697a8;
}

.form-control::placeholder {
  color: #8697a8;
}

input:invalid,
textarea:invalid {
  outline: none !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.125rem rgba(48, 76, 253, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1e7256;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #fff;
  background-color: transparent;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1e7256;
  background-color: #1e7256;
}

.custom-control-label {
  line-height: 1.55;
  padding-left: 0.5rem;
}

.custom-control-label::before {
  background-color: transparent;
  border: 1px solid #f0f0f6;
}

select,
.custom-select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.1rem center/10px 16px;
}

select option,
.custom-select option {
  color: #44566c;
}

.dropdown-menu {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0.5rem 2.5rem rgba(134, 151, 168, 0.25);
  color: #5f6f81;
  font-size: 0.9375rem;
}

.dropdown-menu::before {
  content: "";
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-bottom: 0.375rem solid #fff;
  height: 0;
  position: absolute;
  top: -0.375rem;
  left: 1.0625rem;
  width: 0;
}

.dropdown.dropup .dropdown-menu::before {
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.375rem solid #fff;
  border-bottom: 0.375rem solid transparent;
  top: auto;
  bottom: -0.75rem;
}

.progress {
  background-color: #f0f0f6;
  border-radius: 1rem;
  height: 1.5rem;
  margin-bottom: 1.5rem;
  overflow: inherit;
  position: relative;
  text-align: right;
}

@media only screen and (max-width: 580px) {
  .progress {
    margin-bottom: 1rem;
  }
}

.progress:last-child {
  margin-bottom: 0;
}

.progress-bar {
  background-color: #1e7256;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  width: 0%;
  z-index: 1;
}

.progress .progress-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.progress .progress-text > * {
  min-width: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .progress-text span:nth-child(2) {
    float: right;
  }
}

.badge {
  font-size: 0.9375rem;
  font-weight: 400;
  border-radius: 10rem;
  padding: 0.5rem 1rem;
}

@media only screen and (max-width: 580px) {
  .badge {
    font-size: 0.875rem;
  }
}

.badge--light {
  background-color: #f5f6f7;
  color: #44566c;
}

.badge--gray {
  background-color: #f0f0f6;
  color: #44566c;
}

.badge--dark {
  background-color: #44566c;
  color: #fff;
}

.badge--blue {
  background-color: #1e7256;
  color: #fff;
}

.badge--blue-gradient {
  background-image: linear-gradient(
    to right,
    #0081ff 0%,
    #3fa0ff 50%,
    #0045ff 100%
  );
  color: #fff;
}

.badge--aqua {
  background-color: #00f1e2;
  color: #fff;
}

.badge--red {
  background-color: #ff3d57;
  color: #fff;
}

.tooltip {
  font-family: "Archivo", sans-serif;
}

.tooltip-inner {
  max-width: 12.5rem;
  padding: 0.25rem 0.5625rem 0.25rem;
  background-color: #44566c;
  border-radius: 0.25rem;
  box-shadow: 0 1rem 2rem rgba(134, 151, 168, 0.25),
    0 0.125rem 1rem rgba(134, 151, 168, 0.5);
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-width: 0.3125rem 0.3125rem 0;
  border-top-color: #44566c;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-width: 0.3125rem 0.3125rem 0.3125rem 0;
  border-right-color: #44566c;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-width: 0.3125rem 0 0.3125rem 0.3125rem;
  border-left-color: #44566c;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-width: 0 0.3125rem 0.3125rem;
  border-bottom-color: #44566c;
}

.btn {
  background-color: #1e7256;
  border-radius: 20px;
  border: 0;
  box-shadow: 0 0.5rem 1rem rgba(48, 76, 253, 0.15),
    0 0.125rem 0.25rem rgba(48, 76, 253, 0.15);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: inherit;
  padding: 0.9375rem 2rem 0.875rem;
  position: relative;
  transition: 0.4s ease-in-out;
  white-space: nowrap;
}

.btn:hover {
  color: #fff;
  box-shadow: none;
  opacity: 0.9;
}

.btn:focus {
  box-shadow: 0 1.5rem 2.5rem rgba(48, 76, 253, 0.1),
    0 0.5rem 1rem rgba(48, 76, 253, 0.1);
}

.btn::before {
  content: "";
  box-shadow: 0 0.25rem 2rem rgba(48, 76, 253, 0.25),
    0 0.25rem 1rem rgba(48, 76, 253, 0.25);
  margin: 0 auto;
  height: 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.4s ease-in-out;
  width: 64%;
  z-index: -1;
}

.btn:hover::before {
  box-shadow: none;
}

.btn--light {
  background-color: #f5f8f9;
  color: #44566c;
}

.btn--gray {
  background-color: #f0f0f6;
  color: #44566c;
}

.btn--dark {
  background-color: #44566c;
}

.btn--blue-gradient {
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #0081ff 0%,
    #3fa0ff 50%,
    #0045ff 100%
  );
}

.btn--red {
  background-color: #ff3d57;
  color: #fff;
}

.btn .font-icon {
  margin-right: 0.375rem;
}

button:focus {
  outline: none;
}

/* -----------------------------------------------------------------
    - Preloader
----------------------------------------------------------------- */
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  z-index: 1106;
  opacity: 1;
}

.preloader > * {
  min-width: 0;
}

.preloader__wrap {
  width: 100%;
  max-width: 9.375rem;
}

.preloader__progress {
  background: #fff;
  height: 0.125rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
}

.preloader__progress span {
  background: #1e7256;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
}

.circle-pulse > * {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  transform: scale(0);
  animation: pulse 1.6s linear infinite;
}

.circle-pulse__1 {
  width: 6rem;
  height: 6rem;
  margin-top: -3rem;
  margin-left: -3rem;
  background-color: #1e7256;
}

.circle-pulse__2 {
  width: 4.8rem;
  height: 4.8rem;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background-color: rgba(48, 76, 253, 0.7);
  animation-delay: -0.8s;
}

@keyframes pulse {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* -----------------------------------------------------------------
    - Sidebar
----------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
  .sidebar {
    margin-bottom: 1.875rem;
  }
}

@media only screen and (max-width: 580px) {
  .sidebar {
    margin-bottom: 1rem;
  }
}

.sidebar .avatar {
  margin-top: -8rem;
  margin-bottom: 1.5625rem;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-in-out;
}

.sidebar__user-name {
  font-size: 1.8125rem;
  font-family: "Merriweather", serif;
  margin-bottom: 0.875rem;
  position: relative;
}

@media only screen and (max-width: 580px) {
  .sidebar__user-name {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
}

.sidebar__info {
  padding-top: 1.5625rem;
}

@media only screen and (max-width: 1200px) {
  .sidebar__info {
    text-align: center;
  }
}

@media only screen and (max-width: 580px) {
  .sidebar__info {
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
  }
}

.sidebar .btn {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .sidebar .btn {
    width: 40%;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar .btn {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar .btn {
    width: 100%;
  }
}

.contacts-block {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  .contacts-block {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .contacts-block {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 580px) {
  .contacts-block {
    font-size: 0.9375rem;
  }
}

.contacts-block__item {
  display: flex;
  align-items: center;
  margin-bottom: 0.6875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contacts-block__item > * {
  min-width: 0;
}

@media only screen and (max-width: 1200px) {
  .contacts-block__item {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .contacts-block__item {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.25rem;
  }
}

.contacts-block__item a {
  color: #44566c;
  text-decoration: none;
}

.contacts-block__item a:hover {
  color: #1e7256;
}

.contacts-block .font-icon {
  font-size: 1.125rem;
  margin-right: 0.875rem;
}

.sticky-column.is_stuck {
  margin-top: 1.875rem;
}

.sticky-column.is_stuck .avatar {
  margin-top: 0;
}

/* -----------------------------------------------------------------
    - Sections
----------------------------------------------------------------- */
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}

.box {
  background-color: #fff;
  border-radius: 20px;
  padding: 1.875rem;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
}

@media only screen and (max-width: 580px) {
  .box {
    border-radius: 16px;
    padding: 1.25rem;
  }
}

.box__second {
  background-color: #fff;
  border-radius: 15.3846153846px;
}

@media only screen and (max-width: 580px) {
  .box__second {
    border-radius: 15.3846153846px;
  }
}

.box__large {
  padding: 1.875rem 2.5rem;
}

@media only screen and (max-width: 991px) {
  .box__large {
    padding: 1.875rem;
  }
}

@media only screen and (max-width: 580px) {
  .box__large {
    padding: 1.25rem;
  }
}

.box__large .box-inner::before,
.box__large .box-inner::after {
  width: 2.5rem;
}

@media only screen and (max-width: 991px) {
  .box__large .box-inner::before,
  .box__large .box-inner::after {
    width: 1.875rem;
  }
}

@media only screen and (max-width: 580px) {
  .box__large .box-inner::before,
  .box__large .box-inner::after {
    width: 1.25rem;
  }
}

.box__large .box-inner::before {
  left: -2.5rem;
}

@media only screen and (max-width: 991px) {
  .box__large .box-inner::before {
    left: -1.875rem;
  }
}

@media only screen and (max-width: 580px) {
  .box__large .box-inner::before {
    left: -1.25rem;
  }
}

.box__large .box-inner::after {
  right: -2.5rem;
}

@media only screen and (max-width: 991px) {
  .box__large .box-inner::after {
    right: -1.875rem;
  }
}

@media only screen and (max-width: 580px) {
  .box__large .box-inner::after {
    right: -1.25rem;
  }
}

.box-inner {
  background-color: #f5f8f9;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 580px) {
  .box-inner {
    padding-top: 1.1875rem;
    padding-bottom: 1rem;
  }
}

.box-inner::before,
.box-inner::after {
  content: "";
  background-color: #f5f8f9;
  height: 100%;
  position: absolute;
  top: 0;
  width: 1.875rem;
  z-index: -1;
}

@media only screen and (max-width: 580px) {
  .box-inner::before,
  .box-inner::after {
    width: 1.25rem;
  }
}

.box-inner::before {
  left: -1.875rem;
}

@media only screen and (max-width: 580px) {
  .box-inner::before {
    left: -1.25rem;
  }
}

.box-inner::after {
  right: -1.875rem;
}

@media only screen and (max-width: 580px) {
  .box-inner::after {
    right: -1.25rem;
  }
}

.box-inner--rounded::before {
  border-radius: 0 0 0 20px;
}

.box-inner--rounded::after {
  border-radius: 0 0 20px 0;
}

@media only screen and (max-width: 580px) {
  .box-inner--rounded::before {
    border-radius: 0 0 0 16px;
  }

  .box-inner--rounded::after {
    border-radius: 0 0 16px 0;
  }
}

.box-inner--white {
  background-color: #fff;
}

.box-inner--white::before,
.box-inner--white::after {
  background-color: #fff;
}

.shadow {
  box-shadow: 0 2.5rem 3.125rem rgba(103, 118, 128, 0.1) !important;
}

.shadow::before {
  content: "";
  box-shadow: 0 1.875rem 3.125rem rgba(103, 118, 128, 0.2);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  height: 2rem;
  width: 65%;
  z-index: -1;
}

.shadow--orange {
  box-shadow: 0 2.5rem 3.125rem rgba(177, 68, 27, 0.1) !important;
}

.shadow--orange::before {
  box-shadow: 0 2.5rem 3.125rem rgba(177, 68, 27, 0.1);
}

.shadow--green {
  box-shadow: 0 2.5rem 3.125rem rgba(0, 115, 97, 0.1) !important;
}

.shadow--green::before {
  box-shadow: 0 2.5rem 3.125rem rgba(0, 115, 97, 0.1);
}

.js-image {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.overlay {
  position: relative;
}

.overlay::before,
.overlay--45::before,
.overlay--80::before {
  content: "";
  background-color: rgba(68, 86, 108, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.overlay--45::before {
  background-color: rgba(68, 86, 108, 0.45);
}

.overlay--80::before {
  background-color: rgba(68, 86, 108, 0.8);
}

.gutter-top {
  padding-top: 4rem;
}

@media only screen and (max-width: 580px) {
  .gutter-top {
    padding-top: 9rem;
  }
}

.avatar {
  display: block;
  height: 100%;
  width: 100%;
}

.avatar--180 {
  max-height: 11.25rem;
  max-width: 11.25rem;
  height: 11.25rem;
  width: 11.25rem;
}

.avatar--80 {
  max-height: 6rem;
  max-width: 6rem;
  filter: drop-shadow(-0.15625rem 0 0 #fff) drop-shadow(0.1875rem 0 0 #fff)
    drop-shadow(0 -0.125rem 0 #fff) drop-shadow(0 0.125rem 0 #fff)
    drop-shadow(0 0.25rem 0.25rem rgba(134, 151, 168, 0.25))
    drop-shadow(0 0.5rem 0.5rem rgba(134, 151, 168, 0.4));
}

@media only screen and (max-width: 580px) {
  .avatar--80 {
    max-height: 3.3333333333rem;
    max-width: 3.3333333333rem;
  }
}

.avatar--60 {
  max-height: 3.75rem;
  max-width: 3.75rem;
  filter: drop-shadow(0 0.25rem 0.25rem rgba(134, 151, 168, 0.2))
    drop-shadow(0 0.5rem 0.5rem rgba(134, 151, 168, 0.2));
}

@media only screen and (max-width: 580px) {
  .avatar--60 {
    max-height: 2.8195488722rem;
    max-width: 2.8195488722rem;
  }
}

.avatar__box {
  -webkit-clip-path: url(#avatar-box);
  clip-path: url(#avatar-box);
}

.avatar__hexagon {
  -webkit-clip-path: url(#avatar-hexagon);
  clip-path: url(#avatar-hexagon);
}

.avatar image {
  height: 100%;
  width: 100%;
}

.social {
  line-height: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.375rem;
}

@media only screen and (min-width: 580px) {
  .social {
    margin-top: 1.125rem;
    margin-bottom: 1rem;
  }
}

.social__link {
  color: #44566c;
  display: inline-block;
  font-size: 0.875rem;
  margin-left: 0.8125rem;
  text-decoration: none;
}

.social__link:hover {
  cursor: pointer;
  text-decoration: none;
}

.social__link:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 580px) {
  .pt-sm-6 {
    padding-top: 5rem;
  }
}

/* -----------------------------------------------------------------
    - Navbar
----------------------------------------------------------------- */
.circle-menu {
  background-color: #1e7256;
  border-radius: 50% 1.25rem 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 3.75rem;
  z-index: 2;
}

.circle-menu > * {
  min-width: 0;
}

@media only screen and (max-width: 991px) {
  .circle-menu {
    height: 3.5625rem;
    width: 3.5625rem;
  }
}

@media only screen and (max-width: 580px) {
  .open-menu .circle-menu {
    box-shadow: 0 0 0 0.0625rem rgba(48, 76, 253, 0.5);
    background-color: transparent;
  }
}

.hamburger {
  cursor: pointer;
}

.hamburger .line {
  background-color: #fff;
  border-radius: 0.25rem;
  display: block;
  margin: 0.3125rem 0;
  height: 0.1875rem;
  width: 1.5rem;
  transition: all 0.2s ease-in-out;
}

.hamburger .line:nth-child(2) {
  margin-left: 0.25rem;
  width: 1.25rem;
}

@media only screen and (max-width: 768px) {
  .hamburger .line {
    margin: 0.25rem 0;
    height: 0.125rem;
    width: 1.125rem;
  }

  .hamburger .line:nth-child(2) {
    width: 0.875rem;
  }
}

.hamburger.is-active .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .hamburger.is-active .line:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
  transform: rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media only screen and (max-width: 768px) {
  .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
}

@media only screen and (max-width: 580px) {
  .hamburger.is-active .line {
    background-color: #1e7256;
  }
}

.inner-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.75rem;
  padding-right: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  z-index: 1;
}

.inner-menu > * {
  min-width: 0;
}

@media only screen and (max-width: 991px) {
  .inner-menu {
    height: 3.5625rem;
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .inner-menu {
    width: 100%;
  }
}

.open-menu .inner-menu {
  pointer-events: all;
}

.nav {
  color: #fff;
  background: #1e7256;
  border-radius: 2rem 0 0 2rem;
  box-shadow: 0 1.5rem 2.5rem rgba(48, 76, 253, 0.05),
    0 0.5rem 1rem rgba(48, 76, 253, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.nav > * {
  min-width: 0;
}

@media only screen and (max-width: 768px) {
  .nav {
    border-radius: 1.25rem 0 0 2rem;
    font-size: 0.875rem;
  }
}

.nav__item {
  margin-right: 3rem;
}

@media only screen and (max-width: 991px) {
  .nav__item {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 580px) {
  .nav__item {
    margin-right: 0;
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
}

.nav__item a {
  color: rgba(255, 255, 255, 0.7);
}

.nav__item a:hover {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 580px) {
  .nav__item a {
    color: rgba(68, 86, 108, 0.6);
  }

  .nav__item a:hover {
    color: #44566c;
  }
}

.nav__item a.active {
  color: #fff;
}

@media only screen and (max-width: 580px) {
  .nav__item a.active {
    color: #44566c;
  }
}

.nav__item:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 580px) {
  .nav__item:last-child {
    margin-right: 0;
  }
}

.inner-menu-alt {
  padding-right: 0;
  width: 100%;
}

.inner-menu-alt .nav {
  border-radius: 20px 20px 2rem 2rem;
  width: 100%;
  transition: width 0.4s ease-in-out;
}

@media only screen and (max-width: 580px) {
  .inner-menu-alt .nav {
    width: 0;
  }
}

.inner-menu-alt .nav__item:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 580px) {
  .open-menu .inner-menu-alt .nav {
    border-radius: 0;
    width: 100%;
  }
}

/* -----------------------------------------------------------------
    - Footer
----------------------------------------------------------------- */
.footer {
  color: #8697a8;
  font-size: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

@media only screen and (max-width: 580px) {
  .footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.bg-green .footer,
.bg-peach .footer {
  color: #fff;
}

/* -----------------------------------------------------------------
    - Cases
----------------------------------------------------------------- */
.case-item {
  display: flex;
  height: calc(100% - rem(30px));
  margin-bottom: 1.875rem;
  padding: 1.875rem 1.875rem 1.5rem;
  position: relative;
}

.case-item > * {
  min-width: 0;
}

@media only screen and (max-width: 991px) {
  .case-item {
    height: calc(100% - rem(20px));
  }
}

@media only screen and (max-width: 580px) {
  .case-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - rem(20px));
    margin-bottom: 1.25rem;
    padding: 1rem;
    text-align: center;
  }

  .case-item > * {
    min-width: 0;
  }
}

.case-item__icon {
  margin-right: 1.1875rem;
  height: 2.5rem;
  width: 2.5rem;
}

@media only screen and (max-width: 580px) {
  .case-item__icon {
    margin-right: 0;
    margin-bottom: 0.8125rem;
  }
}

.case-item div {
  flex: 1;
}

.case-item__caption {
  color: #5f6f81;
  line-height: 1.5;
}

.case-item__caption:last-child {
  margin-bottom: 0;
}

/* -----------------------------------------------------------------
    - Clients
----------------------------------------------------------------- */
.js-carousel-clients {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 580px) {
  .js-carousel-clients .swiper-slide {
    text-align: center;
  }
}

/* -----------------------------------------------------------------
    - Contact
----------------------------------------------------------------- */
@media only screen and (max-width: 768px) {
  .contact-form .btn {
    width: 100%;
  }
}

.tabcontent .contact-form {
  padding-bottom: 1.875rem;
}

@media only screen and (max-width: 580px) {
  .tabcontent .contact-form {
    padding-bottom: 1.25rem;
  }
}

.map {
  background-color: #f5f8f9;
  border-radius: 13.3333333333px;
  height: 23.75rem;
  margin-bottom: 2rem;
  position: relative;
  direction: ltr;
}

@media only screen and (max-width: 580px) {
  .map {
    height: 15.625rem;
  }
}

.popover-map-title {
  color: #44566c;
  font-size: 1.1875rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.5rem;
}

.popover-map-caption {
  color: #44566c;
  font-weight: 400;
}

#marker {
  /* background-image: url("../images/icon-map-pic.svg"); */
  background-size: cover;
  width: 3.125rem;
  height: 3.125rem;
  cursor: pointer;
}

/* -----------------------------------------------------------------
    - Portfolio
----------------------------------------------------------------- */
.gutter-sizer {
  width: 1.875rem;
}

.gallery-grid {
  display: flex;
}

.gallery-grid > * {
  min-width: 0;
}

.gallery-grid__item {
  margin-bottom: 1.3125rem;
  width: calc(33% - 17.5px);
}

@media only screen and (max-width: 991px) {
  .gallery-grid__item {
    width: calc(50% - 16px);
  }
}

@media only screen and (max-width: 768px) {
  .gallery-grid__item {
    width: 100%;
  }
}

.gallery-grid__item:hover .gallery-grid__image {
  transform: scale3d(1.1, 1.1, 1);
}

.gallery-grid__image-wrap {
  border-radius: 14px;
  height: 11rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .gallery-grid__image-wrap {
    height: 13rem;
  }
}

@media only screen and (max-width: 768px) {
  .gallery-grid__image-wrap {
    height: 18rem;
  }
}

.gallery-grid__image {
  border-radius: 10px;
  transform: scale3d(1, 1, 1);
}

.gallery-grid__caption {
  padding-left: 0.1875rem;
}

.gallery-grid__title {
  font-size: 0.9375rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0;
}

.gallery-grid__category {
  font-size: 0.875rem;
  color: #8697a8;
  display: block;
}

@media only screen and (min-width: 769px) {
  .filter {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 1.1875rem;
    padding: 0;
  }

  .filter > * {
    min-width: 0;
  }

  .filter__item {
    padding-right: 1.1875rem;
  }

  .filter__item:first-child {
    display: none;
  }

  .filter__item:last-child {
    padding-right: 0;
  }

  .filter__item.active a {
    color: #1e7256;
  }

  .filter__link {
    color: #8697a8;
    padding: 0 0.25rem;
    text-decoration: none;
    display: block;
    overflow: hidden;
    position: relative;
  }

  .filter__link:hover {
    text-decoration: none;
  }
}

.select .placeholder {
  display: none;
}

@media only screen and (max-width: 768px) {
  .select {
    background-color: #f5f8f9;
    border-radius: 1.25rem;
    color: #8697a8;
    display: block;
    margin-bottom: 2rem;
    padding: 0.1875rem 1.1875rem 0.1875rem 1.5rem;
    position: relative;
    vertical-align: middle;
    text-align: left;
    user-select: none;
    width: 100%;
    -webkit-touch-callout: none;
    z-index: 2;
  }

  .select .placeholder {
    cursor: pointer;
    display: block;
    padding: 0.5rem 0;
    position: relative;
    z-index: 1;
  }

  .select .placeholder:after {
    position: absolute;
    top: 50%;
    right: 0.125rem;
    transform: translateY(-50%);
    font-family: "icomoon";
    content: "\e90e";
    z-index: 10;
  }

  .select.is-open .placeholder:after {
    content: "\e90f";
  }

  .select.is-open ul {
    display: block;
  }

  .select ul {
    background-color: #fff;
    box-shadow: 0 0.625rem 3.75rem rgba(134, 151, 168, 0.25);
    border-radius: 20px;
    display: none;
    max-height: 37.5rem;
    overflow: hidden;
    overflow-y: auto;
    padding: 0.5625rem 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  .select ul li {
    display: block;
    text-align: left;
    padding: 0.1875rem 1.1875rem;
    color: #44566c;
    cursor: pointer;
  }

  .select ul li:hover {
    background-color: #1e7256;
  }

  .select ul li:hover a {
    color: #fff;
  }

  .select ul li:first-child {
    display: none;
  }

  .select ul li a {
    color: #44566c;
    text-decoration: none;
  }
}

@media only screen and (max-width: 580px) {
  .select {
    margin-bottom: 1.5rem;
  }
}

/* -----------------------------------------------------------------
    - News
----------------------------------------------------------------- */
.news-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.news-grid > * {
  min-width: 0;
}

@media only screen and (max-width: 991px) {
  .news-grid {
    margin-left: 0;
    margin-right: 0;
  }
}

.news-item {
  box-shadow: 0 1.5rem 2.5rem rgba(169, 194, 209, 0.3);
  margin-bottom: 1.875rem;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  padding: 0;
  width: calc(50% - 30px);
}

@media only screen and (max-width: 991px) {
  .news-item {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 580px) {
  .news-item {
    margin-bottom: 1.25rem;
  }
}

.news-item:hover .news-item__image-wrap::before,
.news-item:hover .news-item__image-wrap::after {
  opacity: 1;
}

.news-item:hover {
  box-shadow: 0 0.75rem 1.25rem rgba(169, 194, 209, 0.2);
}

.news-item__image-wrap {
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  height: 14rem;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .news-item__image-wrap {
    height: 18rem;
  }
}

@media only screen and (max-width: 580px) {
  .news-item__image-wrap {
    border-radius: 16px 16px 0 0;
    height: 12rem;
  }
}

.news-item__image-wrap::before {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.news-item__image-wrap::after {
  content: "";
  /* background-image: url(../icons/icon-news.svg); */
  height: 2.5rem;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  width: 2.5rem;
  z-index: 2;
}

.news-item__date {
  background-color: #fff;
  color: #5f6f81;
  border-radius: 0 0.625rem 0 0;
  font-size: 0.875rem;
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  padding-bottom: 0.1875rem;
  padding-top: 0.3125rem;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.news-item__link {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.news-item__caption {
  padding: 1.875rem 1.875rem 0.5rem;
}

@media only screen and (max-width: 580px) {
  .news-item__caption {
    padding: 1.1875rem 1rem 0;
  }
}

.news-item .title {
  font-family: "Merriweather", serif;
}

.news-item p {
  color: #5f6f81;
  line-height: 1.5;
}

@media only screen and (max-width: 580px) {
  .news-item p {
    font-size: 0.9375rem;
  }
}

/* -----------------------------------------------------------------
    - Single post
----------------------------------------------------------------- */
.header-post {
  margin-top: 1rem;
  text-align: center;
}

@media only screen and (max-width: 580px) {
  .header-post {
    margin-top: 1rem;
  }
}

.header-post__date {
  color: #8697a8;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 580px) {
  .header-post__date {
    margin-bottom: 0.8125rem;
  }
}

.header-post .title {
  font-family: "Merriweather", serif;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 580px) {
  .header-post .title {
    margin-bottom: 1rem;
  }
}

.header-post__image-wrap {
  height: 31.25rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  margin-bottom: 2.5rem;
}

@media only screen and (max-width: 580px) {
  .header-post__image-wrap {
    height: 18rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    margin-bottom: 1.5rem;
  }
}

.caption-post {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 80%;
}

@media only screen and (max-width: 991px) {
  .caption-post {
    width: 100%;
  }
}

@media only screen and (max-width: 580px) {
  .caption-post {
    margin-bottom: 1.1875rem;
  }
}

.caption-post p {
  color: #5f6f81;
}

.gallery-post {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.gallery-post > * {
  min-width: 0;
}

@media only screen and (max-width: 580px) {
  .gallery-post {
    margin-left: 0;
    margin-right: 0;
  }
}

.gallery-post__item {
  flex: calc(50% - 32px);
  height: auto;
  max-height: 80vw;
  margin-left: 1rem;
  margin-right: 1rem;
  width: calc(50% - 32px);
}

@media only screen and (max-width: 580px) {
  .gallery-post__item {
    flex: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
}

.gallery-post__caption {
  color: #8697a8;
  font-size: 0.875rem;
  font-style: italic;
  padding: 1rem 1rem 2rem;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 580px) {
  .gallery-post__caption {
    padding: 0 0 1rem;
  }
}

.gallery-post__caption a {
  color: #8697a8;
}

.footer-post {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
  position: relative;
}

.footer-post > * {
  min-width: 0;
}

@media only screen and (max-width: 580px) {
  .footer-post {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.footer-post::before {
  content: "";
  background-color: #44566c;
  height: 0.0625rem;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 15%;
}

.footer-post__share {
  display: flex;
  align-items: baseline;
  color: #44566c;
  font-size: 0.875rem;
  margin: 0 1.0625rem;
}

.footer-post__share > * {
  min-width: 0;
}

@media only screen and (max-width: 580px) {
  .footer-post__share {
    margin: 0 0.8125rem;
  }
}

.footer-post__share:hover {
  text-decoration: none;
}

.footer-post__share .font-icon {
  margin-right: 0.6875rem;
}

@media only screen and (max-width: 580px) {
  .footer-post__share span {
    display: none;
  }
}

.comment-box {
  display: flex;
  flex-direction: column;
}

.comment-box > * {
  min-width: 0;
}

.comment-box .comment-box {
  margin-left: 3rem;
}

@media only screen and (max-width: 580px) {
  .comment-box .comment-box {
    margin-left: 1.1875rem;
  }
}

.comment-box__inner {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  margin-bottom: 1.25rem;
  padding: 1.25rem 1.25rem 1rem 1.25rem;
}

.comment-box__inner > * {
  min-width: 0;
}

@media only screen and (max-width: 580px) {
  .comment-box__inner {
    flex-direction: column;
    padding: 1rem 1rem 0.5625rem 1.0625rem;
    margin-bottom: 1rem;
  }
}

.comment-box__body {
  color: #5f6f81;
  flex: 1;
  font-size: 0.9375rem;
  line-height: 1.6;
}

@media only screen and (max-width: 580px) {
  .comment-box__body {
    font-size: 0.875rem;
    margin-top: 0.75rem;
  }
}

.comment-box__body p {
  margin-bottom: 0.6875rem;
}

@media only screen and (max-width: 580px) {
  .comment-box__body p {
    margin-bottom: 0.5rem;
  }
}

.comment-box__body .emoji {
  margin: 0 0.3125rem;
  vertical-align: bottom;
}

@media only screen and (max-width: 580px) {
  .comment-box__body .emoji {
    margin: 0 0.125rem;
    height: 1.0625rem;
    width: 1.0625rem;
  }
}

.comment-box__details {
  display: flex;
  justify-content: space-between;
  font-size: 1.0625rem;
  color: #44566c;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.comment-box__details > * {
  min-width: 0;
}

@media only screen and (max-width: 580px) {
  .comment-box__details {
    font-size: 0.9375rem;
    flex-direction: column;
    margin-top: 0;
  }
}

.comment-box__details a {
  text-decoration: none;
}

.comment-box__details-date {
  font-size: 0.875rem;
  font-weight: 400;
  color: #8697a8;
}

@media only screen and (max-width: 580px) {
  .comment-box__details-date {
    font-size: 0.75rem;
    margin-top: 0.3125rem;
  }
}

.comment-box .avatar {
  margin-right: 1rem;
  flex-shrink: 0;
}

.comment-box__footer {
  color: #8697a8;
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.comment-box__footer > * {
  min-width: 0;
}

.comment-box__footer li {
  cursor: pointer;
  margin-right: 2rem;
}

@media only screen and (max-width: 580px) {
  .comment-box__footer li {
    margin-right: 1rem;
  }
}

.comment-box__footer li:hover {
  color: #1e7256;
}

.comment-box__footer li:last-child {
  margin-right: 0;
}

.comment-box__footer span {
  font-size: 0.875rem;
  padding-left: 0.375rem;
}

@media only screen and (max-width: 580px) {
  .comment-box__footer span {
    font-size: 0.75rem;
  }
}

.comment-box__footer .font-icon {
  font-size: 1rem;
  vertical-align: text-bottom;
}

@media only screen and (max-width: 580px) {
  .comment-box__footer .font-icon {
    font-size: 0.875rem;
  }
}

.comment-box__footer .icon-like-fill {
  color: #ff3d57;
}

.comment-form {
  margin-top: 1rem;
  position: relative;
}

.comment-form .textarea {
  border-radius: 0.5rem;
  padding: 1.0625rem 4.5rem 1rem 3.1875rem;
  max-height: 12.5rem;
  overflow: hidden !important;
}

@media only screen and (max-width: 580px) {
  .comment-form .textarea {
    padding: 0.875rem 3.25rem 0.875rem 2.5rem;
  }
}

.comment-form .btn {
  height: 2.5625rem;
  margin-right: 0.5rem;
  margin-bottom: auto;
  margin-top: auto;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5625rem;
}

@media only screen and (max-width: 580px) {
  .comment-form .btn {
    height: 1.8303571429rem;
    width: 1.8303571429rem;
  }
}

.comment-form .btn .font-icon {
  margin: 0;
}

@media only screen and (max-width: 580px) {
  .comment-form .btn .font-icon {
    font-size: 0.75rem;
  }
}

.comment-form .dropdown {
  height: 1.1875rem;
  line-height: 1.2;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
}

.comment-form .dropdown-menu {
  margin-left: -1rem;
  min-width: 14.9375rem;
  max-width: 14.9375rem;
  top: -100% !important;
}

.comment-form .icon-smile {
  color: #8697a8;
  cursor: pointer;
  font-size: 1.1875rem;
}

.comment-form .icon-smile:hover {
  color: #5f6f81;
}

@media only screen and (max-width: 580px) {
  .comment-form .icon-smile {
    font-size: 1rem;
  }
}

.emoji-wrap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0.375rem 0.8125rem 0.1875rem;
}

.emoji-wrap > * {
  min-width: 0;
}

.emoji-wrap .emoji {
  cursor: pointer;
  border-radius: 0.1875rem;
  margin: 0.125rem;
  padding: 0.1875rem;
}

.emoji-wrap .emoji:hover {
  box-shadow: 0 0 0 0.125rem rgba(134, 151, 168, 0.2);
}

.scroll-line {
  background: #1e7256;
  height: 0.1875rem;
  position: fixed;
  top: 0;
  width: 0;
  z-index: 3;
}

.nicescroll-cursors {
  background-color: #8697a8 !important;
}

.social-auth {
  font-size: 0.9375rem;
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

@media only screen and (max-width: 580px) {
  .social-auth {
    font-size: 0.875rem;
  }
}

.social-auth__item {
  display: inline-flex;
  margin-right: 0.375rem;
}

.social-auth__item > * {
  min-width: 0;
}

.social-auth__link {
  color: #fff;
}

.social-auth__link:hover {
  color: #fff;
  opacity: 0.7;
}

.social-auth .font-icon {
  background-color: #1e7256;
  border-radius: 50%;
  font-size: 0.75rem;
  padding: 0.375rem;
}

@media only screen and (max-width: 580px) {
  .social-auth .font-icon {
    font-size: 0.6875rem;
  }
}

.social-auth .icon-facebook {
  background-color: #3b5998;
}

.social-auth .icon-twitter {
  background-color: #55acee;
}

.social-auth .icon-dribbble {
  background-color: #f26798;
}

.social-auth .icon-behance {
  background-color: #4176fa;
}

/* -----------------------------------------------------------------
    - Testimonials
----------------------------------------------------------------- */
.swiper-container .swiper-wrapper {
  min-height: 100%;
}

.review-item {
  display: flex;
  flex-direction: column;
  background-color: #f5f8f9;
  /* background-image: url(../icons/icon-quote.svg); */
  background-repeat: no-repeat;
  background-position: right 1.875rem top 1.875rem;
  border-radius: 20px;
  height: auto;
  padding: 1.875rem 1.875rem 1.5rem;
  margin-top: 2.5rem;
}

.review-item > * {
  min-width: 0;
}

@media only screen and (max-width: 580px) {
  .review-item {
    background-size: 1.5625rem;
    background-position: right 1rem top 1rem;
    border-radius: 13.3333333333px;
    padding: 1rem;
  }
}

.review-item__caption {
  color: #5f6f81;
  line-height: 1.5;
}

.review-item__caption:last-child {
  margin-bottom: 0;
}

.review-item .title {
  font-family: "Merriweather", serif;
  font-size: 1.1875rem;
  margin-left: 5.5rem;
  margin-bottom: 0.6875rem;
}

@media only screen and (max-width: 580px) {
  .review-item .title {
    font-size: 1rem;
    margin-left: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.review-item .avatar {
  position: absolute;
  top: -2.375rem;
}

.swiper-pagination {
  margin-top: 1.5rem;
  position: relative;
  line-height: 0;
}

@media only screen and (max-width: 580px) {
  .swiper-pagination {
    margin-top: 1rem;
  }
}

.swiper-pagination-bullet {
  background: #f0f0f6;
  border-radius: 1rem;
  opacity: 1;
  height: 0.625rem;
  width: 0.625rem;
  transition: width 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media only screen and (max-width: 580px) {
  .swiper-pagination-bullet {
    height: 0.375rem;
    width: 0.375rem;
  }
}

.swiper-pagination-bullet-active {
  background: #1e7256;
  width: 1.25rem;
}

@media only screen and (max-width: 580px) {
  .swiper-pagination-bullet-active {
    width: 0.75rem;
  }
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 0.3125rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

/* -----------------------------------------------------------------
    - Timeline
----------------------------------------------------------------- */
.timeline {
  margin-top: 1.8125rem;
  padding-left: 0.5rem;
}

.timeline__item {
  border-left-color: #f0f0f6;
  border-left-width: 1px;
  border-left-style: solid;
  padding-bottom: 0.5625rem;
  padding-left: 1.375rem;
  position: relative;
}

.timeline__item::before {
  content: "";
  background-color: #1e7256;
  box-shadow: 0 0 0 0.1875rem rgba(48, 76, 253, 0.25);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -0.3125rem;
  height: 0.625rem;
  width: 0.625rem;
}

.timeline__item:last-child {
  border: 0;
}

.timeline__title {
  margin-bottom: 0;
  position: relative;
  top: -0.3125rem;
}

.timeline__period {
  color: #8697a8;
  font-size: 0.875rem;
}

.timeline__description {
  margin-top: 0.5rem;
}

@media only screen and (max-width: 580px) {
  .timeline__description {
    margin-top: 0.1875rem;
  }
}

/* -----------------------------------------------------------------
    - Other
----------------------------------------------------------------- */
.back-to-top {
  border-top: 0.0625rem solid #44566c;
  border-left: 0.0625rem solid #44566c;
  cursor: pointer;
  height: 2rem;
  opacity: 0.5;
  position: fixed;
  right: 2.5rem;
  bottom: 1.875rem;
  width: 2rem;
  transform: rotate(45deg);
  transition: opacity 0.35s ease-out;
  z-index: 10;
}

.back-to-top:hover {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .back-to-top {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media only screen and (max-width: 580px) {
  .back-to-top {
    display: none !important;
  }
}

.js-lines {
  overflow: hidden;
}

.js-lines div {
  overflow: hidden;
}

.js-words div div {
  opacity: 0;
}

.medium-zoom-overlay {
  background: #fff !important;
  z-index: 1104;
}

.medium-zoom-image {
  cursor: pointer !important;
  z-index: 1104;
}

.medium-zoom-image--opened {
  border-radius: 0.0625rem;
  height: 100%;
}

/*# sourceMappingURL=style.css.map */
