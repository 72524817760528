.timeline__item::after {
    content: '';
    display: inline-block;
    position: absolute;
    background-color: #1e7256;
    border-radius: 10px;
    width: 22px;
    height: 3px;
    top: 2%;
    left: 0px;
    z-index: 0;
}




.resume-period {
    background: #e6f5f0a8;
    padding: 14px;
    box-shadow: -1px 0px 3px 2px #bec3c0b0;
    margin: 5px auto;
}

.title-icon {
    margin-right: 0.5rem;
    margin-top: -0.25rem;
    height: 51px;
    width: 51px;
}

.timeline__period {
    position: absolute;
    top: 8px;
    right: 7px;
    padding: 1px 5px;
    border-radius: 10px;
    color: #1e7256;
    box-shadow: inset 0px 0px 3px 0px grey;

}

.title--h5 {
    font-size: 18px;
}

.timeline__place {
    color: #354a43ba;
}

.timeline__description {
    margin-bottom: 0.5rem;
}

.soft_skills {
    margin-top: 25px;
}

.title--h3 {
    font-size: 1.7rem;
}