.mi-competence-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}

.circle-progress {
    width: 150px;
    height: 150px;
}