button {
    --c: #1e7256;
    color: var(--c);
    font-size: 16px;
    border: 0.3em solid #1e7256;
    /* border-color: #1e7256;
    border-bottom-color: #1e7256;
    border-right-color: #1e7256; */
    border-radius: 0.5em;
    height: 3em;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 0.1em;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    width: 100%;
}

button span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: var(--c);
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;

}

button:hover {
    color: rgb(255, 255, 255);
}

button:hover span {
    transform: translateY(0) scale(2.5);
}

button span:nth-child(1) {
    --n: 1;
}

button span:nth-child(2) {
    --n: 2;
}

button span:nth-child(3) {
    --n: 3;
}

button span:nth-child(4) {
    --n: 4;
}