/** Testimonials  **/

.dots-defult .slick-dots li,
.dots-long .slick-dots li {
    width: auto;
    height: auto;
}

.dots-defult .slick-dots li button:before,
.dots-long .slick-dots li button:before {
    content: none;
}

.dots-defult .slick-dots li button,
.dots-long .slick-dots li button {
    margin: 0;
    padding: 0;
    height: 10px;
    width: 10px;
    background: #fff;
    border-radius: 10px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.dots-defult.d-primary .slick-dots li button,
.dots-long.d-primary .slick-dots li button {
    background: #1e7256;
    opacity: 0.5;
}

.dots-long .slick-dots li.slick-active button {
    width: 25px;
    opacity: 1;
}

.dots-defult .slick-dots li.slick-active button {
    opacity: 1;
}

.dots-defult .slick-dots,
.dots-long .slick-dots {
    position: unset;
    bottom: 0;
    margin-top: 20px;
}


.slick-slider {
    cursor: grab;
}

.slick-slider:active {
    cursor: grabbing;
}

.slick-slide {
    padding: 0 15px;
}

.review-item {
    position: relative;
}

.review-item .avatar {
    position: absolute;
    top: -1.5rem;
    left: 1rem;
}

.review-item .title {
    font-size: 1.5rem;
    position: relative;
    top: -20px;
}

.testimonial-designation {
    margin-left: 5.5rem;
    position: relative;
    top: -29px;
}

.review-item__captiontestimonial-text {
    margin-top: -10px;
}

.quotes {
    font-size: 4rem;
    position: absolute;
    top: 0.5rem;
    right: 2.5rem;
    color: #8fb9aba6;
}

/**** Clients ***/
.client-section .slick-track {
    display: flex;
    align-items: center;
}


.client-div img {
    transition: transform 1s;
}

.client-div img:hover {
    transform: scale(1.15);
    /* box-shadow: -15px 20px 12px 0px grey; */
    /* box-shadow: -1px 2px 20px 2px grey; */
    /* border-radius: 15px; */
}