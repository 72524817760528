.carousel .carousel-status {
    display: none;
}

.carousel.carousel-slider .control-arrow {
    top: 43%;
    background-color: #1e7256;
    height: fit-content;
    width: auto;
}



.carousel .control-arrow {
    width: auto;
    color: black;
    margin-top: 0;
    top: 0;
    border: dashed 1px;
    background: #bcefde;

}

.active-thumbnail,
.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #1e7256;
    border-radius: 5px;

}

.carousel .control-next.control-arrow:before {
    content: "❯" !important;
    border: none !important;
}

.carousel .control-prev.control-arrow:before {
    content: "❮" !important;
    border: none !important;
}

.carousel .slider-wrapper {
    height: 400px;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    height: 100%;
}

.carousel .thumb {
    height: 60px;
}


.carousel .control-dots .dot {
    background-color: #1e7256;
}

.wpo-project-img {
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: #2f340d7e;
        background: #101100e1;
        content: "";
        opacity: 0;
        z-index: 1;
        transition: all .3s;
        visibility: hidden;
    }

    .left-border {
        position: absolute;
        left: 25px;
        top: 20px;
        width: 100%;
        height: 100%;
        z-index: 11;

        &:before {
            position: absolute;
            left: 0;
            top: 15px;
            height: 1px;
            width: 0;
            background: white;
            content: "";
            transition: all .3s;

        }

        &::after {
            position: absolute;
            left: 15px;
            top: 0;
            height: 0;
            width: 1px;
            background: white;
            content: "";
            transition: all .3s;
        }
    }

    .right-border {
        position: absolute;
        right: 25px;
        bottom: 20px;
        width: 100%;
        height: 100%;
        z-index: 11;

        &:before {
            position: absolute;
            right: 0;
            bottom: 15px;
            height: 1px;
            width: 0;
            background: white;
            content: "";
            transition: all .3s;
        }

        &::after {
            position: absolute;
            right: 15px;
            bottom: 0;
            height: 0;
            width: 1px;
            background: white;
            content: "";
            transition: all .3s;
        }
    }

    .wpo-project-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 111;
        transition: all .3s;
        opacity: 0;
        visibility: hidden;

        h2 {
            font-size: 40px;
            line-height: 58px;
            color: #f9f9f9;

            @media(max-width:575px) {
                font-size: 30px;
                line-height: 45px;
            }

            a {
                color: white;

                &:hover {
                    color: #1e7256;
                }
            }
        }

        span {
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            color: white;

            @media(max-width:575px) {
                font-size: 16px;
            }
        }
    }

    &:hover {
        &:before {
            opacity: .5;
            visibility: visible;
        }

        .left-border {
            &:before {
                width: 300px;

                @media(max-width:1500px) {
                    width: 200px;
                }

                @media(max-width:450px) {
                    width: 120px;
                }
            }

            &::after {
                height: 300px;

                @media(max-width:1500px) {
                    height: 200px;
                }

                @media(max-width:450px) {
                    height: 120px;
                }

            }
        }

        .right-border {
            &:before {
                width: 300px;

                @media(max-width:1500px) {
                    width: 200px;
                }

                @media(max-width:450px) {
                    width: 120px;
                }
            }

            &::after {
                height: 300px;

                @media(max-width:1500px) {
                    height: 200px;
                }

                @media(max-width:450px) {
                    height: 120px;
                }
            }
        }

        .wpo-project-text {
            opacity: 1;
            visibility: visible;
        }
    }
}