/*===========================
  ******** Progress Bar ********
  =============================*/

.mi-progress {
    margin: 12px 5px;
}

.mi-progress-title {
    color: black;
}

.mi-progress-title {
    font-weight: 600;
    margin-bottom: 0;
}

.mi-progress-inner {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.mi-progress-percentage {
    flex: 0 0 60px;
    right: 2px;
    position: absolute;
}

.mi-progress-container {
    background: #d1d7d5;
    display: block;
    height: 6px;
    width: 100%;
    position: relative;
    border-radius: 20px;
}

.mi-progress-active {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #1e7256;
    transition: all 2.5s cubic-bezier(0.01, 0.57, 0.68, 1.05) 0s;
    border-radius: 20px;
}

.skills_title {
    font-size: 1.45rem;
    margin: 10px auto;
    margin-left: 3px;
}

.mi_skills_section {
    border: dashed 1px #1e725645;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}