.main {
    background-color: #F8FBFA;
}

a {
    text-decoration: none;
}

@media(max-width:768px) {
    .main_box {
        padding-top: 13%;

    }
}

@media only screen and (max-width: 580px) {
    .main_box {
        padding-top: 18%;

    }

}