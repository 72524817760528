.Portfolio_Title {
  position: relative;
  top: -1rem;
  color: #47685d;
  font-size: 1.8rem;
  margin-bottom: 0;
}

.Portfolio_Title_a {
  color: #47685d;
  font-size: 1.8rem;
}

.Portfolio_Title_a:hover {
  text-decoration: none;
  color: #1e7256;
}

blockquote {
  background: #e6f5f0a8;
  padding: 14px;
  box-shadow: -1px 0px 3px 2px #bec3c0b0;
  margin: 5px auto;
  border-left: 5px solid #99d7c2cd;
}

.project_description {
  font-size: 1.2rem;
  background-color: #f5f8f9;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  z-index: 0;
  display: inherit !important;
}

.project_btn {
  background-color: #1e7256f5;
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem rgb(48 76 253 / 15%),
    0 0.125rem 0.25rem rgb(48 76 253 / 15%);
  color: #fff;
  /* cursor: pointer; */
  /* font-size: 1rem; */
  /* font-weight: 400; */
  /* line-height: inherit; */
  padding: 0.9rem 2rem;
  /* position: relative; */
  transition: 0.4s ease-in-out;
  white-space: nowrap;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project_btn:hover {
  background-color: #fff;
  color: #1e7256f5;
}

.NextPreviousProject {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  color: #1e7256;
}

.NextPreviousProject h6 {
  box-shadow: 0 0 2px 1px #80808082;
  padding: 5px;
  border-radius: 7px;
}

.NextPreviousProject h6 a {
  color: #1e7256;
  text-decoration: none;
}

.NextPreviousProject h6:hover,
.NextPreviousProject h6:hover a {
  background-color: #1e7256;
  color: white;
  transition: 0.3s all;
  text-decoration: none;
}

.NextPreviousProject h6 .NPicon {
  margin: 0 5px;
}

@media only screen and (max-width: 464px) {
  .Portfolio_Title {
    top: 0;
  }
}
.technologies {
  margin-top: 25px;
}
.technologies .tech-div {
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 30px;
}
.technologies div img:hover {
  transform: scale(1.5);
  transition: all 0.7s;
  z-index: 33;
}

.technologies div img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.technologies div .tech-img {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.technologies div .tech-img h5 {
  display: none;
  position: absolute;
  margin-top: 52px;
}
.technologies div img {
  margin-right: 10px;
  margin-left: 10px;
}
.tech-img-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.technologies div .tech-img-div:hover h5 {
  display: flex;
}
