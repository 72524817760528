@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in-image {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    transition: all 1s;
}

.fade-out-image {
    animation: fadeOut 1s;
    -webkit-animation: fadeOut 1s;
    -moz-animation: fadeOut 1s;
    -o-animation: fadeOut 1s;
    -ms-animation: fadeOut 1s;
}

.filter_btn {
    box-shadow: 0 0.5rem 1rem rgb(30 114 86 / 21%), 0 0.125rem 0.25rem rgb(30 114 86 / 41%);
    margin: auto 5px;
    font-weight: 700;
    color: #44566C;
}


.btn:hover,
.active {
    /* background-color: #c3fdea; */
    background-color: #d3e5df;
    box-shadow: none;
    opacity: .9;
}

.btn::before {
    content: none;
}

.btn:hover::before {
    box-shadow: none;
}

.portfolio_card {
    margin: 10px 0;
}

.dlab-gallery-box {
    background: #d3e5df;
    padding: 5px;

    border-radius: 22px;

    box-shadow: 0px 0px 5px 1px grey;
    position: relative;
    height: 100%;
}

.dlab-img-effect {
    border-radius: 22px;
    position: relative;
    overflow: hidden;
}

.dlab-img-effect img {
    height: 210px;
    width: 100%;
    transition: all 0.5s ease-out;
    object-fit: contain;
}

.dlab-box:hover .dlab-img-effect img {
    transform: scale(1.15);
}

.portfolio_card_title {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    padding: 4px;
    background-color: #1e7256c2;
    font-size: 1.5rem;
    color: white;
}